@import 'colors';

.btn-primary {
  border: 1px solid $color-lightblue;
  background-color: $color-lightblue;
  color: $color-white;
  padding: 9px;
  line-height: 1;
  min-width: 80px;
  width: fit-content;
}
.btn-primary:disabled {
  background-color: $color-disabled;
  border: 1px solid $color-disabled;
}
.btn-primary:hover {
  color: $color-white;
  background-color: $color-world-peace;
  border: 1px solid $color-darkgray;
}
.btn-primary:focus {
  border: 1px solid $color-lightblue;
  background-color: $color-lightblue;
}

.btn-secondary,
.icon-button {
  border: 1px solid $color-darkgray;
  background-color: $color-white;
  color: $color-lightblue;
  padding: 9px;
  line-height: 1;
  min-width: 80px;
  width: fit-content;
  &:focus {
    border: 1px solid $color-darkgray;
    background-color: $color-white;
    color: $color-lightblue;
  }
}

.icon-button {
  display: flex;
  align-items: center;
  color: $color-lightblue;
  width: unset;
  span {
    margin-right: 6px;
  }
}

.icon-action-button {
  height: 38px;
  width: 38px;
  padding: 6px;
  background-color: $color-white;
  border-color: $color-lightblue;
  img {
    width: 16px;
  }
}

.btn-secondary:disabled,
.icon-button:disabled {
  background-color: $color-wild-sand;
  border: 1px solid $color-darkgray;
  color: $color-disabled;
}
.btn-secondary:hover,
.icon-button:hover {
  color: $color-lightblue;
  background-color: $color-wild-sand;
  border: 1px solid $color-darkgray;
}

.btn-tertiary {
  border: 1px solid $color-darkgray;
  background-color: $color-darkred;
  color: $color-white;
  padding: 9px;
  line-height: 1;
  width: 80px;
}
.btn-tertiary:hover {
  color: $color-lightblue;
  background-color: $color-darkgray;
  border: 1px solid $color-darkgray;
}

.btn-primary,
.btn-secondary,
.btn-tertiary {
  &.no-width {
    width: unset;
  }
}

.btn-close:focus,
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.btn-quaternary {
  background-color: white;
  border: 1px solid #ccc;
  color: #00709e;
  height: 38px;
  margin-right: 7px;

  border-radius: 0.25rem;
  padding-left: 14px;
  padding-right: 14px;

  img {
    padding-bottom: 2px;
  }

  &:disabled {
    color: $color-disabled;
  }
}

.with-margin-for-label {
  margin-top: 30px;
}

.btn-inline-img {
  display: flex;
  align-items: center;
  flex-direction: row;
  img {
    margin-left: 5px;
    width: 14px;
  }
}

.btn-no-padding-background {
  border: none;
  background: none;
}

.underline-btn {
  text-decoration: underline;
  color: $color-lightblue;
  &:hover {
    text-decoration: none;
  }
}
