@import 'colors';
@import 'sizes';

.clickable {
  cursor: pointer;
}

.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                          supported by Chrome, Edge, Opera and Firefox */
}

.create-record-container,
.manage-record-container {
  margin: auto;
  width: $main-box-width;
  margin-bottom: 50px;
}

.create-record-container {
  margin-top: 60px;
}

.border-box-wrapper,
.collapsible-wrapper {
  border-radius: 4px;
  border: solid 1px #ccc;
  background-color: #fff;
  padding: 18px 25px;
  margin-bottom: 5px;
}

.outcome-grid {
  display: grid;
  margin-top: 15px;
  row-gap: 20px;

  .border-bottom {
    border-bottom: 1px solid black;
  }

  div.cell {
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;

    &.no-padd {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.menu-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-header {
  &.has-menu {
    display: grid;
    grid-template-columns: auto min-content;

    :last-child .button-wrapper {
      right: 28px;
    }
  }
}

.spacing-lg {
  letter-spacing: 1.1px;
}

.collapsible-button {
  display: grid;
  grid-template-columns: 304px auto;
  justify-items: start;
  background-color: transparent;

  &.has-menu {
    grid-template-columns: max-content auto max-content max-content;
    align-items: flex-start;
  }
}

.uparrow:before {
  content: '\276E';
}

.downarrow:before {
  content: '\276F';
}

.uparrow,
.downarrow {
  font-size: 1.25rem;
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  position: relative;
  padding-left: 2px;
  margin-left: 25px;
  margin-right: 10px;
}

option[disabled] {
  color: $color-darkgray;
}

.field-container {
  .title {
    font-weight: bold;
  }
}

.edit-mode-disabled {
  color: $color-darkgray;
  pointer-events: none;
  cursor: default;
}

.edit-mode-disabled:hover {
  background-color: $color-white !important;
  cursor: default;
}

//This is in bootstrap already
//.form-control:disabled,
//This was removed when upgrading to ang 14
.form-control:focus[readonly],
.form-control[readonly] {
  background-color: $color-disabled-bg;
  border: 1px solid #ced4da;
  box-shadow: unset;
  opacity: 1;
}

div.separator {
  width: 100%;
  margin: 18px 0;
  border-bottom: $color-darkgray 1px solid;
}

.form-panel {
  background-color: $color-off-white;
}

input[type='date']:focus.editable,
input[type='date'].editable {
  background-color: #fff;
}

//style the tiny mce background color when disabled
.tox-tinymce--disabled div {
  background-color: #e9ecef !important;
}

.text-row {
  margin-bottom: 25px;
}

.button-wrapper {
  position: relative;

  .options {
    padding-left: 7px;
    padding-right: 7px;
  }

  button {
    background-color: white;
    border: 1px solid #ccc;
    color: #00709e;
    height: 38px;
    margin-right: 7px;
  }

  .options-button {
    border-radius: 50%;
    width: 38px;
  }

  .top-button {
    border-radius: 0.25rem;
    padding-left: 14px;
    padding-right: 14px;

    img {
      padding-bottom: 2px;
    }
  }
}

.required-for-approval-banner {
  margin-bottom: 20px;
}

.ng-dropdown-panel {
  z-index: 1200 !important;
}

.add-another-link {
  height: 20px;
  margin: 10px 79.2px 48.2px 1.3px;
  font-family: Inter;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: $color-blue;
  text-decoration: underline;
  cursor: pointer;
}

/*Don't allow the selectlist to wrap when a large number of items are selected*/
.in-line-select {
  .ng-select {
    &.ng-select-multiple {
      .ng-select-container {
        .ng-value-container {
          flex-wrap: nowrap !important;
          min-width: 4px;

          .ng-value {
            white-space: nowrap;
            min-width: 0;
            display: flex;
            max-width: 95%;

            .ng-value-label {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}

hr.dashed {
  border-style: dashed;
  border-width: 1px;
}

a.inherit {
  text-decoration: none !important;
  color: inherit !important;
}

.sub-heading {
  font-weight: 500;
}

.link {
  text-decoration: underline;
  color: $color-lightblue;
  cursor: pointer;
}

.shift-padding-left {
  padding-right: 16px;
}
