$color-black: #131313;
$color-white: #ffffff;

$color-darkgray: #cccccc;
$color-mediumgray: #f8f8f8;
$color-lightgray: #f2f2f2;
$color-iron: #dededf;
$color-shady-lady: #9a9999;
$color-disabled: #8b8b8b;
$color-disabled-bg: #e9ecef;
$color-gray: #6a6a6a;
$color-almost-black: #30383a;
$color-off-white: #fbfbfb;
$color-ghost-white: #f8fafb;
$color-gemini-gray: #707070;

$color-warning: #f49f08;
$color-darkyellow: #e3ac00;
$color-lightred: #d11600;
$color-red: #a50634;
$color-darkred: #7a0932;

$color-lightblue: #00709e;
$color-blue: #007382;
$color-darkblue: #004150;
$color-world-peace: #005274;

$color-purple: #763c83;
$color-darkpurple: #430950;

$color-lightgreen: #65a625;
$color-green: green;
$color-darkgreen: #195a00;
$color-successgreen: #00772e;

$color-record-status-bg-color: #0d77a3;
$color-create-form-bg-color: #f8f8f8;

$color-anakiwa: #86fbfc;
$color-pickled-bluewood: #2f3b52;
$color-ebony-clay: #242e42;
$color-ebony-clay-dark: #20293c;
$color-wild-sand: #f5f5f5;
$color-outer-space: #2f3337;
$color-pickled-bluewood-dark: #2c364c;
$color-silver: #bbbbbb;
$color-input-border: #ced4da;
$color-consent-name-bg: #143b70;
