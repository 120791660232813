@import 'colors';
@import 'sizes';
@import 'background-images';

.main-body {
  margin: auto;
  width: $main-box-width;
  justify-content: space-between;
  font-family: Inter;
  font-size: 1rem;
}

.tree-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $color-darkgray;
}

.browse-frame {
  display: flex;
  padding-bottom: 2rem;
  padding-top: 5rem;
  padding-left: 120px;
  padding-right: 120px;
  flex-direction: column;
}

.page-header {
  height: 238px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-blue;
  background-image: url($background-pattern);
  color: $color-white;
  .main-title {
    margin-top: 52px;
    font-family: Inter;
    font-size: $font-sm;
    letter-spacing: 1.8px;
    text-align: center;
  }
  .main-title::before {
    content: '-  ';
  }
  .main-title::after {
    content: '  -';
  }
  .sub-title {
    margin-top: 34px;
    max-width: 37%;
    font-family: Inter;
    font-size: $main-form-label-font-size;
    line-height: 1.5;
    text-align: center;
    color: $color-wild-sand;
  }
}
.details-wrapper {
  line-height: 1.31;
  letter-spacing: normal;
  display: flex;

  align-items: left;
  font-size: 1rem;
  font-weight: 500;
  font-family: Mulish;

  .single-row {
    text-align: left;
    font-weight: normal;
  }

  h1 {
    display: inline;
    text-align: left;
    font-weight: 800;
    font-size: 26px;
  }

  h2 {
    display: inline;
    text-align: left;
    font-weight: 500;
    font-size: 15px;
  }

  .chevron {
    font-size: 120%;
    font-weight: 500;
    vertical-align: bottom;
  }

  .title {
    word-wrap: break-word;
    overflow: auto;
  }

  .value {
    display: inline-flex;
    margin-left: 5px;
    gap: 5px;
    text-align: left;
    align-items: center;
    font-weight: 600;
  }

  .divider {
    margin-right: 10px;
    margin-left: 10px;
    &.divider-sm {
      margin-right: 5px;
      margin-left: 5px;
    }
  }
}

.readonly-mode {
  margin-bottom: 0;
  word-wrap: break-word;
}
