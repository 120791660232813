@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf');
}

@font-face {
  font-family: 'Mulish';
  src: url('/assets/fonts/Mulish/Mulish-VariableFont_wght.ttf'),
    url('/assets/fonts/Mulish/Mulish-Italic-VariableFont_wght.ttf');
}
