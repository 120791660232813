@import 'colors';

.mat-radio-button,
.mat-mdc-radio-button {
  margin-right: 25px;
}
.mat-expansion-panel-header-title {
  cursor: default;
  min-height: 45px;
}
.mat-expansion-panel-body {
  padding-bottom: 0px;
}
.mat-radio-disabled {
  .mat-radio-container {
    .mat-radio-inner-circle {
      opacity: 0.5;
    }
  }
}
.middle-radio-button {
  padding-left: 20px;
  padding-right: 20px;
}

.mdc-radio__inner-circle {
  border-color: $color-lightblue !important;
}
.mdc-radio__outer-circle {
  border-color: $color-lightblue !important;
}

.mat-expansion-panel-header {
  padding-left: 0px;
}
.mat-radio-label-content {
  span {
    font-family: Inter;
    font-size: 1.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $color-black;
  }
}
