.checkbox-container {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  //Note that this is 2 because the first label (type) is always the question for the radio/checkbox.
  &:nth-of-type(2) {
    margin-top: 0;
  }

  //Note that .has-margin may be required because in rare cases there is text under the radio/checkbox, so we actually do want the margin there.
  //But in the majority of cases, we don't because the fieldset ending will give us our relevant margin.
  &:last-of-type:not(.has-margin) {
    margin-bottom: 0;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    background-color: $color-white;
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: solid 1px $color-darkgray;
    top: 4px;
    left: 0;
    border-radius: 2px;
  }

  &:hover input ~ .checkmark {
    // background-color: $color-alto;
  }

  input:checked ~ .checkmark {
    // checked background
    background-color: $color-blue;

    &:after {
      content: '';
      position: absolute;
      left: 5.5px;
      top: 2px;
      width: 7px;
      height: 12px;
      border: solid $color-white;
      border-width: 0 2.5px 2.5px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input:disabled ~ .checkmark {
    background-color: white;
  }

  &.form-control {
    font-size: 0.75rem;
    padding-left: 20px;
    line-height: 20px;
  }
}
