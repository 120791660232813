$min-page-width: 1260px;
$main-box-width: 1074px;
$header-height: 80px;

$main-form-circle-font-size: 0.875rem;
$main-form-title-font-size: 1.125rem;
$main-form-label-font-size: 1rem;

$dialog-close-x: 22px;
$dialog-header-title-main-font-size: 2rem;
$dialog-header-title-font-size: 1.375rem;
$dialog-header-title-font-size-small: 12px;

$font-xlg: 32px;
$font-lg: 22px;
$font-mdlg: 18px;
$font-md: 14px;
$font-sm: 12px;

.font-xlg {
  font-size: $font-xlg;
}

.font-lg {
  font-size: $font-lg;
}

.font-mdlg {
  font-size: $font-mdlg;
}

.font-md {
  font-size: $font-md;
}

.font-sm {
  font-size: $font-sm;
}

.form-sm {
  label.title {
    font-size: 1rem;
    margin: 0 0 11px 0;
    font-family: Inter;
  }
}

.fw-600 {
  font-weight: 600;
}

.font-size-h4 {
  font-size: 23.5px;
}
