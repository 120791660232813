@import 'colors';
@import 'sizes';

.modal-content {
  border: none;
}
.modal-header {
  border: none;
  padding-bottom: 0;
  font-family: Mulish;
  font-size: 1.125rem;
  color: $color-black;
}

.modal-body {
  padding: 0 0 20px 0;
  margin: 0 30px;
  color: $color-black;
  font-family: Inter;
  font-size: 1rem;

  .modal-title,
  .modal-title h2 {
    margin: 0px 0 30px 0;
    font-family: Mulish;
    font-size: 22px !important;
    font-weight: 600;
  }
}

hr:not([size]) {
  height: 0.6px;
}

.modal-footer {
  border-top: 1px solid $color-darkgray;
  padding: 20px 0;
  margin: 0 30px;
}

.qcr-modal-md .modal-dialog {
  width: 620px;
  max-width: 620px;
  min-width: 620px;
}

.qcr-modal-md-plus .modal-dialog {
  width: 920px;
  max-width: 920px;
  min-width: 920px;
}

.qcr-modal-lg .modal-dialog {
  width: 90%;
  max-width: 1872px;
  min-width: $main-box-width;
}

.modal-full-screen,
.qcr-modal-xlg {
  overflow: hidden !important;

  .modal-dialog {
    width: 98%;
    max-width: 98%;
    overflow-y: initial !important;

    .modal-content {
      height: calc(100vh - 50px);
      overflow-y: auto;
    }
  }
}

.modal-content:has(.no-footer) {
  height: calc(100vh - 109px) !important;
}

.btn-close.btn-close-white {
  opacity: 1;
  //If we want a hover effect
  // &:hover {
  //   opacity: 0.75;
  // }
}
