@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~material-icons/iconfont/material-icons.css';
//@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* You can add global styles to this file, and also import other style files */
@import 'typography';
@import 'colors';
@import 'elements';
@import 'sizes';
@import 'checkbox';
@import 'background-images';
@import 'mat-radio-button';
@import 'rule-group';
@import 'dragula';

$font-family-base: 'Inter';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'layout';
@import 'modal';
@import 'buttons';

html,
body {
  //overflow: auto;
  color: $color-almost-black;
}

.header {
  color: $color-almost-black;
}

::placeholder {
  font-size: 0.75rem;
  font-style: italic;
  color: $color-disabled !important;
}

select.form-control {
  -webkit-appearance: menulist;
  height: 38px;
}

.validation-warning,
.validation-error {
  font-size: 0.75rem;
  text-align: right;
  font-weight: normal;
}

.validation-error {
  color: $color-lightred;
}

.validation-error-block {
  font-size: 0.75rem;
  text-align: justify;
  font-weight: normal;
  background: #7a0932;
  color: white;
  border-radius: 4px;
}

.validation-warning {
  color: $color-warning;
}

.form-label.required:after {
  color: $color-lightred;
  content: '*';
  margin-left: 1px;
}

.has-error {
  border: 1px solid $color-lightred;
}

.has-warning {
  border: 1px solid $color-warning;
}

.field-container {
  min-height: 100px;
}

.filter-gray {
  filter: brightness(0) saturate(100%) invert(93%) sepia(0%) saturate(2869%) hue-rotate(314deg) brightness(91%)
    contrast(88%);
}

.has-search .form-control {
  padding-left: 36px;
}

.has-search .form-control-button {
  position: absolute;
  width: 36px;
  line-height: 36px;
  text-align: center;
}

.form-check-input:checked {
  background-color: $color-lightblue;
}

.tox-statusbar {
  border-top: none !important;
  justify-content: flex-end !important;
}

.result-header {
  .main {
    font-size: 1.375rem;
  }
  .subtext {
    font-size: 1rem;
    color: $color-disabled;
  }
}

.warning-message {
  background-color: $color-warning;
  color: $color-white;
  padding: 11px 22px 9px;
  margin-top: 10.5px;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
  background-color: white;
  translate: -13px 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: $color-lightblue;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.mat-mdc-option:not(.mdc-list-item--selected) {
  color: $color-disabled;
  opacity: 0.6;
}

.not-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.contents {
  display: contents;
}

.button-row {
  display: flex;
  justify-content: flex-end;

  > * {
    margin-left: 20px; // Adjust this value as needed
  }

  > *:first-child {
    margin-left: 0;
  }
}
