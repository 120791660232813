.rule-group-header {
  margin: 55px 473px 34px 0px;
  font-size: 1.375rem;
  font-weight: 600;
  word-wrap: break-word;
  word-break: break-all;
}

.header-context {
  margin: 15px 0 0 6px;
}

.panel-header {
  font-family: Mulish;
  font-size: 1.125rem;
  font-weight: 600;
}
